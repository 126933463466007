export const identityBind = {
    // 重新绑定提示
    bindKey: {
        operateName: '',
        title: '提示',
        icon: 'icon icon-fail icon-color-warn',
        dialogDes1: '参数缺失或不正确',
        operateData: {},
        button1Code: 'search-confirm',
        button1Name: '确认',
        button2Code: '',
        button2Name: ''
    },
    bindSuccess: {
        operateName: '',
        title: '提示',
        icon: 'icon icon-dui icon-color-success',
        dialogDes1: '绑定成功',
        dialogDes2: '可前往开放平台进行业务接入',
        operateData: {},
        button1Code: 'go-open',
        button1Name: '确认',
        button2Code: '',
        button2Name: ''
    }
}
//企业实名信息提示框信息
export const enterRelInfo= {
    saveDraftS: {
        operateName: '',
        title: '提示',
        icon: 'icon icon-dui icon-color-success',
        dialogDes1: '草稿保存成功',
        dialogDes2: '',
        operateData: {},
        button1Code: 'search-confirm',
        button1Name: '确认',
        button2Code: '',
        button2Name: ''
    },
    isIE: {
        operateName: '',
        title: '提示',
        icon: 'icon icon-fail icon-color-warn',
        dialogDes1: '该浏览器暂不支持pdf预览',
        dialogDes2: '推荐使用chrome浏览器',
        operateData: {},
        button1Code: 'search-confirm',
        button1Name: '确认',
        button2Code: 'search-cancle',
        button2Name: '取消'
    }
}