<template>
  <div class="dialog-wrap">
    <el-dialog
      :title="dailogData.title"
      :visible.sync="isvisible"
      width="30%"
      center
      @open="openDialog"
      @close="onClose()"
    >
      <div class="content-center">
        <div slot="content" class="content">
          <i class="icon-size" :class="dailogData.icon"></i>
          <div class="desc1">{{ dailogData.dialogDes1 }}</div>
          <div class="dialogDes2">{{ dailogData.dialogDes2 }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="handleConfirm(dailogData.button1Code)"
          >{{ dailogData.button1Name }}</el-button
        >
        <el-button
          type="primary"
          @click="onClose()"
          v-if="dailogData.button2Name"
          >{{ dailogData.button2Name }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  formatDateTime,
  currentTime,
  quarterTime,
  currentMonth,
  currentYear
} from '@/utils'
export default {
  props: {
    // 弹框所需信息
    dailogData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 弹框是否显示
    isvisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog:false
    }
  },
  mounted() {},
  methods: {
    openDialog() {
      // this.showDialog = this.isvisible
      // console.log('弹框打开普通', this.dailogData)
    },
    // 关闭弹框
    onClose() {
      this.$emit('colseDialog' ,false)
    },
    // 确定或者其他功能按钮
    handleConfirm(val) {
      this.$emit('operateButton',val)
    },
  }
}
</script>
<style lang="scss">
.dialog-wrap {
  .el-button {
    height: 42px;
    line-height: 42px;
    padding: 0 43px;
    border-radius: 21px;
    background-image: linear-gradient(90deg, #2a499d 0%, #3160ba 100%),
      linear-gradient(#3677d6, #3677d6);
    background-blend-mode: normal, normal;
    box-shadow: 0px 2px 3px 0px rgba(49, 96, 186, 0.44);
  }
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
}
.content-center {
  display: flex;
  justify-content: center;
  .content {
    text-align: center;
  }
}
.dialogDes2 {
  margin-top: 10px;
  font-size: 12px;
  color: #181e27;
}
.desc1 {
  font-size: 16px;
  color: #181e27;
}
.icon-size {
  font-size: 72px;
}
.icon-color-warn {
  color: rgb(243, 169, 93);
}
.icon-color-success {
  color: rgb(130, 189, 83);
}
.start-end-time {
  ::v-deep  .el-date-editor--daterange {
    width: 245px !important;
  }
  .el-select {
    width: 245px !important;
  }
}
</style>
