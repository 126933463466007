
<template>
  <div class="authProgress">
    <AppHeader :type="1"></AppHeader>
    <div style="width:1200px;margin:0 auto;padding-top:80px">
      <Crumb :items="crumbData"></Crumb>
    </div>
    <div class="content">
      <div class="cmenu">
        <div class="left-top">
          <div class="company-icon">
            <img src="@/assets/head.png" alt="" />
          </div>
          <p class="company-name">{{ corName }}</p>
        </div>
        <div class="left-bottom">
          <div class="path-item">
            <router-link to="/accountInfo" tag="span">账号信息</router-link>
          </div>
          <div class="path-item active">
            <router-link to="/bind" tag="span">至信链身份绑定</router-link>
          </div>
        </div>
      </div>
      <div class="right">
        <div>
          <div class="elem-item">
            <div class="elem-title">
              <p class="title-label">链上身份</p>
            </div>
            <div class="elem-content"  v-if="isbinded">
              <el-form :inline="true" label-width="150px">
                <el-form-item label="APP ID:" class="ff">
                  <el-input
                    v-model="form.appId"
                    disabled="disabled"
                    readonly="readonly"
                  ></el-input>
                  <span
                    class="copy"
                    data-clipboard-action="copy"
                    @click="copyContent(form.appId)"
                    >复制</span
                  >
                </el-form-item>

                 <el-form-item label="APP KEY:" class="ff">
                  <el-input
                    v-model="form.key"
                    disabled="disabled"
                    readonly="readonly"
                  ></el-input>
                  <span
                    class="copy"
                    data-clipboard-action="copy"
                    @click="copyContent(form.key)"
                    >复制</span
                  >
                </el-form-item>
                <el-form-item label="公钥:" class="ff">
                  <el-input
                    v-model="form2.publicKey"
                    disabled="disabled"
                    readonly="readonly"
                  ></el-input>
                  <span
                    class="copy"
                    data-clipboard-action="copy"
                    @click="copyContent(form2.publicKey)"
                    >复制</span
                  >
                </el-form-item>
                <el-form-item label="签名:" class="ff">
                  <el-input
                    v-model="form2.sign"
                    disabled="disabled"
                    readonly="readonly"
                  ></el-input>
                  <span
                    class="copy"
                    data-clipboard-action="copy"
                    @click="copyContent(form2.sign)"
                    >复制</span
                  >
                </el-form-item>
              </el-form>
              <div class="bind-wrap">
                <el-button  v-if="isbinded" :disabled="!isCreated" type="primary" @click="onReBind"
                  >重新绑定</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <div class="my-step" v-if="!isbinded">
          <div class="progressImg">
            <div class="step">
              <div style="clear:both"></div>
              <div class="img">
                <img src="@/assets/plateform/step-1-1.png" alt="" />
                <div>
                  <span class="circle" :class="{ active: curStep >= 1 }">1</span
                  > <span class="ft-grey">开发工具下载</span>
                </div>
              </div>
              <p class="cardText">
                下载<span class="t1"
                  ><a
                  style="color: #244BB0;text-decoration: none;"
                  href="/downloads/至信链公私钥生成工具.zip"
                  download="至信链公私钥生成工具.zip"
                  >公私钥生成工具</a
                > </span>
                  <!-- 及
                  <span class="t1">
                    <a
                  style="color: #244BB0;text-decoration: none;"
                  href="/downloads/zhixin-sdk-java.zip"
                  download="JAVA-SDK.zip"
                  >JAVA-SDK开发包</a>
                  </span>
                  <br />信息认证 -->
              </p>
            </div>
            <div class="arrow">
              <div class="line">
                <img src="@/assets/arrow.png" alt="" />
              </div>
            </div>
            <div class="step">
              <div style="clear:both"></div>
              <div class="img" v-if="showCurStep">
                <!-- <img src="@/assets/plateform/step2.png" alt="" /> -->
                <img v-if="curStep == 1" src="@/assets/plateform/step-2-2.png" alt="" />
                <img v-if="curStep > 1" src="@/assets/plateform/step-2-1.png" alt="" />
                <div>
                  <p class="circle" :class="{ active: curStep >= 2 }">2</p>
                  <span class="ft-grey">生成公私钥</span>
                </div>
              </div>
              <p class="cardText">
                <span class="">在本地生成企业公私钥</span>
              </p>
            </div>
            <div class="arrow">
              <div class="line">
                <img src="@/assets/arrow.png" alt="" />
              </div>
            </div>
            <div class="step">
              <div style="clear:both"></div>
              <div class="img"  v-if="showCurStep">
                <!-- <img src="@/assets/plateform/bind.png" alt="" /> -->
                <img v-if="curStep == 3" src="@/assets/plateform/step-3-1.png" alt="" />
                <img v-if="curStep < 3" src="@/assets/plateform/step-3-2.png" alt="" />
                <div>
                  <p class="circle" :class="{ active: curStep == 3 }">3</p>
                  
                               <span class="ft-grey">绑定公钥</span>
                </div>
              </div>
              <p class="cardText">在页面内按要求绑定公钥完成企业身份绑定</p>
            </div>
          </div>


          <div class="step-cont"  v-if="showCurStep">
            <div v-if="curStep == 2" >
                <el-form class="form" ref="form" :model="form"  label-width="150px">
                <el-form-item label="AppID">
                  <el-input
                    disabled
                    v-model="form.appId"
                  ></el-input>
                   <span
                    class="copy"
                    data-clipboard-action="copy"
                    @click="copyContent(form.appId)">复制</span>
                </el-form-item>
                <div class="tip">
                     将企业ID粘贴到公私钥生成工具中，生成企业公私钥及签名
                </div>   
                <el-form-item label="AppKey：">
                  <el-input
                    disabled
                    v-model="form.key"
                  ></el-input>
                  <span
                    class="copy"
                    data-clipboard-action="copy"
                    @click="copyContent(form.key)">复制</span>
                </el-form-item>
              </el-form>

             
            </div>

            <div v-if="curStep == 3">
             <el-form
                class="form"
                ref="form"
                :model="form2"
                label-width="150px"
              >
                <el-form-item v-if="isReBind" label="企业ID：">
                  <el-input
                    disabled
                    v-model="form.appId"
                  ></el-input>
                   <span
                    class="copy"
                    data-clipboard-action="copy"
                    @click="copyContent(form.appId)"
                    >复制</span
                  >
                </el-form-item>
                <el-form-item :label="!isbinded ? '公钥：' : ''">
                  <el-input
                    maxlength="256"
                    v-if="!isbinded"
                    :disabled="!isCreated"
                    placeholder="请输入"
                    v-model="form2.publicKey"
                  ></el-input>
                  <p style="word-break: break-all;" v-else>
                    公钥：{{ form2.publicKey }}
                  </p>
                </el-form-item>
                <el-form-item :label="!isbinded ? '签名：' : ''">
                  <el-input
                    maxlength="256"
                     placeholder="请输入"
                    v-if="!isbinded"
                    :disabled="!isCreated"
                    v-model="form2.sign"
                  ></el-input>
                  <p style="word-break: break-all;" v-else>
                    签名：{{ form2.sign }}
                  </p>
                </el-form-item>
                <el-form-item v-if="isReBind" label="预留邮箱：">
                  <p style="text-align:left;width:540px;display:flex">
                  <span>{{ form2.email }}</span>
                   <span v-show="!isInter" @click="getVertify" class="suf suffix"
                    >获取邮箱验证码</span>
                  <span v-show="isInter" class="suf suffix2">({{ inter }}s)</span>
                  </p>

                </el-form-item>
                <el-form-item v-if="isReBind" label="输入验证码：">
                  <el-input
                    maxlength="6"
                    placeholder="请输入验证码"
                    v-model="form2.vertify">
                  </el-input>
                  
                </el-form-item>
              </el-form>
            </div>
          </div>

          <div class="step-btn"  v-if="showCurStep">
               <el-button v-if="curStep == 1" type="primary" @click="goNext(2)">已下载，下一步</el-button>
               <el-button v-if="curStep == 2 && !isCreated" type="primary" @click="onCreate">生成</el-button>
               <el-button v-if="curStep == 2 && isCreated" type="primary" @click="goNext(3)">已生成，下一步</el-button>
               <el-button v-if="curStep == 3 && !isbinded" type="primary" @click="onBind">绑定</el-button>
          </div>
          <template  v-if="showCurStep">
           <div class="step-img"  v-if="curStep > 1 && !isReBind">
                <span style="margin-bottom:5px;display:inline-block"> <i class="red">*</i> 参考图示，当前该工具仅支持windows版本</span>
                <img :src="stepImg" alt="">
            </div>
          </template>
        </div>

        <!-- <div class="cont">
          <div class="step step2">
            <div class="left">
              <div class="num">
                <img src="@/assets/one.png" alt />
              </div>
              <p class="p1">公私钥生成</p>
              <p class="p4">下载“至信链公私钥生成工具”使用“APPID”</p>
              <p class="p4">在工具内生成公私钥及签名</p>
            </div>
            <div class="right">
              <a
                href="/downloads/至信链公私钥生成工具.zip"
                download="至信链公私钥生成工具.zip"
              >
                <img src="@/assets/gongwenbao.png" alt />
              </a>
              <p style="margin-top: 29px;font-size: 14px">
                下载
                <a
                  style="color: #244BB0;text-decoration: none;"
                  href="/downloads/至信链公私钥生成工具.zip"
                  download="至信链公私钥生成工具.zip"
                  >至信链公私钥生成工具</a
                >
                及
                <a
                  style="color: #244BB0;text-decoration: none;"
                  href="/downloads/zhixin-sdk-java.zip"
                  download="JAVA-SDK.zip"
                  >JAVA-SDK开发包</a
                >
              </p>
            </div>
          </div>
          <div class="step step1">
            <div class="left">
              <div class="num">
                <img src="@/assets/two.png" alt />
              </div>
              <p class="p1">APPID&APPKEY生成</p>
              <p class="p2">点击“生成”按钮生成APPID与APPKEY</p>
            </div>
            <div class="right">
              <el-form class="form" ref="form" :model="form" label-width="80px">
                <el-form-item :label="!isCreated ? 'AppID：' : ''">
                  <el-input
                    v-if="!isCreated"
                    disabled
                    v-model="form.id"
                  ></el-input>
                  <p v-else>
                    AppID：{{ form.appId }}

                  </p>
                </el-form-item>
                <el-form-item :label="!isCreated ? 'AppKey：' : ''">
                  <el-input
                    v-if="!isCreated"
                    disabled
                    v-model="form.key"
                  ></el-input>
                  <p v-else>
                    AppKey：{{ form.key }}
                  </p>
                </el-form-item>
                <el-form-item>
                  <el-button
                    v-if="!isCreated"
                    class="footBtn"
                    type="primary"
                    @click="onCreate"
                    >生成</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="step step3">
            <div class="left">
              <div class="num">
                <img src="@/assets/three.png" alt />
              </div>
              <p class="p1">公钥绑定</p>
              <p class="p4" v-if="!isbinded && !isReBind">
                将工具生成的“公钥”、“签名”信息复制到右侧输入栏。
              </p>
              <p class="p3">注意：私钥请妥善保管</p>
            </div>
            <div class="right">
              <el-form
                class="form"
                ref="form"
                :model="form2"
                label-width="100px"
              >
                <el-form-item :label="!isbinded ? '公钥：' : ''">
                  <el-input
                    maxlength="256"
                    v-if="!isbinded"
                    :disabled="!isCreated"
                    v-model="form2.publicKey"
                  ></el-input>
                  <p style="word-break: break-all;" v-else>
                    公钥：{{ form2.publicKey }}
                  </p>
                </el-form-item>
                <el-form-item :label="!isbinded ? '签名：' : ''">
                  <el-input
                    maxlength="256"
                    v-if="!isbinded"
                    :disabled="!isCreated"
                    v-model="form2.sign"
                  ></el-input>
                  <p style="word-break: break-all;" v-else>
                    签名：{{ form2.sign }}
                  </p>
                </el-form-item>
                <el-form-item v-if="isReBind" label="预留邮箱：">
                  <p style="text-align:left">{{ form2.email }}</p>
                </el-form-item>
                <el-form-item v-if="isReBind" label="输入验证码：">
                  <el-input
                    maxlength="6"
                    placeholder="请输入验证码"
                    v-model="form2.vertify"
                  >
                  </el-input>
                  <i v-show="!isInter" @click="getVertify" class="suf suffix"
                    >获取邮箱验证码</i
                  >
                  <i v-show="isInter" class="suf suffix2">({{ inter }}s)</i>
                </el-form-item>
                <el-form-item>
                  <el-button
                    v-if="!isbinded"
                    :disabled="!isCreated"
                    class="footBtn"
                    type="primary"
                    @click="onBind"
                    >绑定</el-button
                  >
                  <el-button
                    v-if="isbinded"
                    :disabled="!isCreated"
                    class="footBtn2"
                    type="primary"
                    @click="onReBind"
                    >重新绑定公钥</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div> -->
        
      </div>
    </div>
    <AppFooter></AppFooter>
      <!-- 提示框组件 -->
    <Dialog
      :isvisible.sync="isvisible"
      :dailogData="dailogData"
      @colseDialog="colseDialog"
      @operateButton="operateButton"
    >
    </Dialog>
  </div>
</template>
<script>
import AppHeader from "@/components/layer/AppHeader";
import AppFooter from "@/components/layer/AppFooter";
import { setInterval, clearInterval } from "timers";
import Dialog from '@/components/common/Dialog';
import Crumb from "@/components/common/Crumb";
import { identityBind } from "./dialog"
import {
  getApply,
  checkIdCard,
  getAppDetail,
  creatGen,
  uploadCert,
  updateCert,
  sendCertCode
} from "@/api/user";
import { resolve } from 'url';
export default {
  components: {
    AppHeader,
    AppFooter,
    Crumb,
    Dialog
  },
  data() {
    return {
      isvisible: false, // 是否显示提示框
      dailogData:{},
      stepImg:require("@/assets/接入指南密钥生成.png"),
      curStep:1, // 当前的步骤
      showCurStep:true,
      crumbData: [
        {
          name: "首页",
          path: ""
        },
        {
          name: "至信链身份绑定",
          path: ""
        }
      ],
      status: 1,
      corName: "",
      exist: 0,
      form: {
        appId: "",
        id: "",
        key: ""
      },
      form2: {
        publicKey: "",
        sign: "",
        vertify: "",
        email: ""
      },
      isCreated: false,
      isbinded: false,
      isReBind: false,
      inter: 60,
      isInter: false,
      bindSuccess: false,
      bindfault: false,
      loading: ""
    };
  },
  mounted() {
    const that = this;
    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
    this.form2.email = JSON.parse(localStorage.getItem("userData")).email;
    this.idCardCheck().then(res=>{
      if(this.exist == 1){
          this.getAppDetail();
      }
    })
    // that.status = 3

    if(this.exist == 1){
      getApply().then(res => {
        this.loading.close();
        if (res.retCode === 0) {
          console.log("res.details", res.detail);
          that.corName = res.detail.epName;
          that.status = res.detail.state;
        } else {
          this.$message.error(res.retMsg);
        }
      });
    }else{
       this.loading.close();
    }
 
  },
  methods: {
    goNext(num){
      console.log("num",num)
      this.showCurStep = false
      this.$nextTick(()=>{
          this.showCurStep = true
          this.curStep = num
      })
    },
    copyContent(con) {
      let url = con;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success"
      });
      oInput.remove();
    },
    idCardCheck() {
        let that = this;
        return new Promise(function(reslove,reject){
             checkIdCard().then(res => {
                that.$message.closeAll();
                if (res.retCode === 0) {
                  that.exist = res.exist;
                  if(!that.exist){ // 尚未实名
                      that.$confirm('尚未实名认证,是否去实名?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        that.$router.push({ path: '/enterpriseInfo' })
                      }).catch(() => {
                      });
                  }
                  reslove(res.exist)
                } else {
                  that.$message.error(res.retMsg);
                  reject()
                }
              });
        })
      
     
    },
    getAppDetail() {
      const that = this;
      getAppDetail().then(res => {
        this.$message.closeAll();
        if (res.retCode == 0) {
          if (res.detail.length > 0) {
            if (res.detail[0].appTid === "") {
              that.isCreated = false;
              that.isbinded = false;
              that.isReBind = false;
            } else if (
              res.detail[0].epPubKey === "" &&
              res.detail[0].appId !== ""
            ) {
              that.isCreated = true;
              that.form.appId = res.detail[0].appId;
              that.form.id = res.detail[0].appTid;
              that.form.key = res.detail[0].appKey;
              that.isbinded = false;
              that.isReBind = false;
            } else if (
              res.detail[0].epPubKey !== "" &&
              res.detail[0].appId !== ""
            ) {
              that.isCreated = true;
              that.form.appId = res.detail[0].appId;
              that.form.id = res.detail[0].appTid;
              that.form.key = res.detail[0].appKey;
              that.form2.publicKey = res.detail[0].epPubKey;
              that.form2.sign = res.detail[0].sign;
              that.isbinded = true;
              that.isReBind = false;
            }
          } else {
            that.isCreated = false;
            that.isbinded = false;
            that.isReBind = false;
          }
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    creatGen() {
      const data = {
        name: "default"
      };
      const that = this;
      creatGen(data).then(res => {
        this.$message.closeAll();
        if (res.retCode == 0) {
          that.getAppDetail();
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    uploadCert() {
      const data = {
        appTid: Number(this.form.id),
        epPubKey: this.form2.publicKey,
        sign: this.form2.sign,
        certInfo: "default"
      };
      const that = this;
      uploadCert(data).then(res => {
        console.log(res);
        this.$message.closeAll();
        this.loading.close();
        if (res.retCode == 0) {
          that.isbinded = true;
          that.isReBind = false;  
          that.$confirm(
              '<i style="font-size: 54px;color: #1DCF99;" class="el-icon-success"></i><span style="position: relative;top: -10px;margin-left: 30px;">公钥绑定成功</span>',
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                dangerouslyUseHTMLString: true,
                center: true,
                cancelButtonClass: "carcer",
                confirmButtonClass: "comfirm"
              }
            )
            .then(() => {})
            .catch(() => {});
        } else {
          //区分验证码提示信息因为已经有3601这个码暂时前端这样区分，后期修改后统一处理
          if(res.retCode===3601){
            identityBind.bindKey.dialogDes1="应用身份校验不通过,应用禁止访问"
          }
          that.isvisible = true;
          that.dailogData = identityBind.bindKey
        }
      });
    },
    onBind() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      if (this.isReBind) {
        this.updateCert();
      } else {
        this.uploadCert();
      }
    },
    onCreate() {
      this.creatGen();
      // this.isCreated = true;
    },
    sendCertCode() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const that = this;
      sendCertCode().then(res => {
        this.$message.closeAll();
        this.loading.close();
        if (res.retCode == 0) {
          that.isInter = true;
          const inters = setInterval(() => {
            that.inter--;
            if (that.inter <= 0) {
              clearInterval(inters);
              that.isInter = false;
              that.inter = 60;
            }
          }, 1000);
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    onReBind() {
      this.curStep = 3
      this.form2.publicKey = ''
      this.form2.sign = ''
      this.form2.vertify = ''
      this.isReBind = true;
      this.isbinded = false;

    },
    updateCert() {
      const data = {
        appTid: Number(this.form.id),
        epPubKey: this.form2.publicKey,
        sign: this.form2.sign,
        certInfo: "default",
        verifyCode: this.form2.vertify
      };
      const that = this;
      updateCert(data).then(res => {
        this.$message.closeAll();
        this.loading.close();
        if (res.retCode == 0) {
          that.isbinded = true;
          that.isReBind = false;
          that
            .$confirm(
              '<i style="font-size: 54px;color: #1DCF99;" class="el-icon-success"></i><span style="position: relative;top: -10px;margin-left: 30px;">公钥绑定成功</span>',
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                dangerouslyUseHTMLString: true,
                center: true,
                cancelButtonClass: "carcer",
                confirmButtonClass: "comfirm"
              }
            )
            .then(() => {})
            .catch(() => {});
        } else {
          that
            .$confirm(
              '<i style="font-size: 54px;color: #D34349;" class="el-icon-error"></i><span style="position: relative;top: -10px;margin-left: 30px;">' +
                res.retMsg +
                "</span>",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                dangerouslyUseHTMLString: true,
                center: true,
                cancelButtonClass: "carcer",
                confirmButtonClass: "comfirm"
              }
            )
            .then(() => {})
            .catch(() => {});
        }
      });
    },
    getVertify() {
      this.sendCertCode();
    },
     colseDialog(val) {
      this.isvisible = val;
    },
    operateButton(val) {
      if (val == "search-confirm") {
        //直接关闭
        this.isvisible = false;
      }
      if(val=="go-open"){
        //进入开放可前往开放平台进行业务接入hashcertificate/overview
        window.open(`${window.redirectUrl.open}/hashcertificate/overview`)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.authProgress {
  min-height: 100vh;
  background: #eff1f4;
  a {
    color: #4066d0;
  }
  .content {
    display: flex;
    width: 1200px;
    height: auto;
    margin: 0px auto 0px;
    box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
    .cmenu {
      width: 200px;
      flex-shrink: 0;
      background-color: #f7f8fa;
      justify-content: center;
      flex-wrap: wrap;
      .left-top {
        height: 170px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        border-bottom: 1px solid #e6e6e6;
        .company-icon {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 30px;
          img {
            width: 100%;
          }
        }
        .company-name {
          color: #333333;
          font-size: 18px;
          width: 100%;
          text-align: center;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      .left-bottom {
        width: 100%;
        .path-item {
          width: 100%;
          height: 54px;
          position: relative;
          margin-top: 0px;
          text-align: center;
          font-size: 16px;
          color: #2f5ab2;
          line-height: 54px;
          cursor: pointer;
        }
        .active {
          background-color: rgba($color: #2f5ab2, $alpha: 0.2);
        }
        .path-item.active::after {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 8px;
          height: 100%;
          background-color: #2f5ab2;
        }
      }
    }
    .right {
      width: 100%;
      min-height:calc(100vh - 260px);
      background: #fff;
      padding: 35px;
      .elem-item {
        width: 100%;
        margin-bottom: 40px;
        .elem-title {
          position: relative;
          display: flex;
          justify-content: space-between;
          height: 40px;
          .title-label {
            font-size: 16px;
            color: #333333;
            margin-left: 12px;
            margin-top: 0;
            i {
              cursor: pointer;
              color: #2f5ab2;
            }
          }

          .title-right {
            font-size: 16px;
            color: #2f5ab2;
            margin: 0;
            cursor: pointer;
          }
          border-bottom: 1px solid #e6e6e6;
        }
        .elem-title::after {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          height: 24px;
          width: 6px;
          border-radius: 3px;
          background-color: #2f5ab2;
        }
        .elem-content {
          margin-top: 40px;
          .el-input {
            width: 540px;
            height: 40px;
          }
          .copy {
            color: #3060b9;
            margin-left: 24px;
            cursor: pointer;
          }
          .bind-wrap {
            text-align: center;
            margin-top: 30px;
          }
        }
      }
      .cont {
        width: 800px;
        height: auto;
        box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
        margin: 44px auto 0;

        .topImg {
          width: 100%;
          height: 157px;
        }
        .step {
          width: 892px;
          margin: 0 auto;
          padding: 61px 0;
          display: flex;
          .left {
            width: 30%;
            text-align: center;
            .p2 {
              color: #244bb0;
              font-size: 14px;
            }
            .p3 {
              color: #ce5e59;
              font-size: 14px;
            }
            .p4 {
              color: #999999;
              font-size: 14px;
            }
          }
          .right {
            width: 70%;
            text-align: center;
            img {
              margin-top: 50px;
              margin-left: 80px;
            }
            .form {
              width: 80%;
              margin: 0 auto;
            }
            p {
              margin: 0;
              text-align: center;
              .copy {
                float: right;
                color: #c44e4e;
                cursor: pointer;
              }
            }
           
          }
        }
        .step2 {
          border-top: 1px solid #eaeaea;
          border-bottom: 1px solid #eaeaea;
        }
        .step1 {
          border-bottom: 1px solid #eaeaea;
        }
        .footBtn {
          width: 160px;
          height: 50px;
          font-size: 18px;
          color: #fff;
          background: linear-gradient(
            -90deg,
            rgba(49, 97, 187, 1) 0%,
            rgba(42, 72, 156, 1) 100%
          );
          border-radius: 4px;
          border: none;
        }
        .footBtn2 {
          border: 1px solid #264ea4;
          color: #264ea4;
          font-size: 18px;
          background: #fff;
          width: 160px;
          height: 50px;
        }
      }
      .my-step {
        .progressImg {
          // width: 1000px;
          margin: 25px auto 0;
          display: flex;
          .step {
            flex: 0 0 190px;
            width: 190px;
            background: #fff;
            .ft-grey{
              font-size:17px;
              font-family:PingFang SC;
              font-weight:600;
              color:rgba(49,70,89,1);
            }
            .circle {
              display: inline-block;
              width: 27px;
              height: 27px;
              border-radius: 27px;
              background:rgba(225,225,225,1);
              color: #666666;
              line-height: 27px;
              text-align: center;
              margin: 6px 8px 0 0;
            }
            .active {
              background-color: #6682d1;
              color: #fff;
            }
            .img {
              text-align: center;
              margin-top: 25px;
              img {
                height: 71px;
              }
              p {
                font-size: 18px;
              }
            }
            .cardText {
              text-align: center;
              padding: 0 0px;
              margin: 16px 0 0 0;
              font-size: 15px;
              font-weight:600;
              color:rgba(49,70,89,1);
              a {
                color: #4066d0;
                text-decoration: none;
              }
              .t1 {
                color: #4066d0;
                cursor: pointer;
                text-decoration: none;
              }
            }
          }
          .arrow {
            flex: 1;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            .line {
              margin-top: 36px;
              height: 0px;
              flex: 1;
              border-top: 1px dashed #b5c7f8;
              text-align: center;
              position: relative;
              img {
                margin-top: -9px;
                position: absolute;
                left: 50%;
                margin-left: -5px;
              }
            }
          }
        }
        .step-img{
          margin: 0 auto;
          margin-top: 40px;
          width:390px;
          height:282px;
          background:rgba(255,255,255,1);
          border:1px dashed rgba(214,214,214,1);
          border-radius:6px;
          padding: 20px 50px;
          img{
            width: 100%;
            height: calc(100% - 20px);
          }
          .red{
            color: red;
          }
        }
        .step-btn{
          text-align: center;
          margin-top: 20px
        }
        .step-cont{
           margin-top:60px;
           .tip{
             color: #999;
             font-size: 14px;
             padding-left: 155px;
    margin-top: -13px;
    margin-bottom: 10px;
           }
           .el-input {
            width: 540px;
            height: 40px;
           }
          .copy {
            color: #3060b9;
            margin-left: 24px;
            cursor: pointer;
          }
           .suf {
              // position: absolute;
              // right: 0;
              margin-left: 25px
            }
            .suffix {
              font-style: normal;
              margin-right: 20px;
              font-size: 14px;
              color: #244bb0;
              cursor: pointer;
            }
            .suffix2 {
              font-style: normal;
              margin-right: 20px;
              font-size: 14px;
              color: #b4b4b4;
            }
        }
      }
    }
  }
}
</style>
